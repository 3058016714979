<template>
    <div>
        <v-btn small class="ml-2 lighten-2" :loading="loading.url" v-if="updateMode && !listMode" @click.stop="printGrossMargin()">
            <v-icon small>far fa-file-pdf</v-icon>
            <span class="ml-1">{{ $t('message.pdf') }}</span>
        </v-btn>
        <v-list-item class="font-sm" @click.stop="printGrossMargin()" v-if="updateMode && listMode">
            <v-progress-circular :size="20" :width="2" indeterminate color="red lighten-1" class="mr-2" v-if="loading.url"/>
            <v-icon color="red lighten-1" small v-else>fas fa-file-pdf</v-icon>
            <span class="ml-2">{{ $t('message.pdf') }}</span>
        </v-list-item>
    </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import {api} from "Api";
// import {log, makeId} from "Helpers/helpers";

export default {
    name: "PrintGrossMarginButton",
    props: ['grossMarginId','updateMode','listMode'],
    data() {
        return {
            url: null,
            loading: {
                url: false
            }
        }
    },
    computed: {
        ...mapFields('runtime',{
            openedTabs: 'openedTabs'
        }),
    },
    methods: {
        getPrintPdfUrl() {
            return new Promise((resolve, reject) => {
                api
                    .get("/print-url/gm/" + this.grossMarginId)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        printGrossMargin() {
            this.loading.url = true;
            this.getPrintPdfUrl()
                .then(response => {
                    if(response.data.status == 'success') {
                        let tab = window.open(response.data.url, '_blank');
                        tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                        this.openedTabs.push(tab.name)
                    } else {
                        this.$toast.error(this.$t('message.errors.pdfError'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            })
                    }
                    this.loading.url = false
                })
                .catch(() => {
                    this.$toast.error(this.$t('message.errors.pdfError'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        })
                    this.loading.url = false
                })
        }
    }
}
</script>